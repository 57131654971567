<!--会员充值退款汇总-->
<!--<div class='RechargeRefundSummary'></div>-->
<template>
    <div class="RechargeRefundSummary" style="background-color: #f2f2f2; margin-top: 8px">
        <el-card shadow="never" style="margin-top: 8px">
            <el-form
                @submit.native.prevent
                :inline="true"
                style="text-align: right"
                v-model="form"
                size="small"
                label-width="85px"
            >
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="机构组名称">
                            <el-select v-model="form.deptGroupCode" @change="handleSelectDeptGroup">
                                <el-option label="请选择" value="" />
                                <el-option
                                    v-for="item in meta.deptGroups"
                                    :label="item.name"
                                    :value="item.code"
                                    :key="item.code"
                                />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="机构名称">
                            <el-select v-model="form.deptCode">
                                <el-option label="请选择" value="" />
                                <el-option
                                    v-for="item in meta.types"
                                    :label="item.name"
                                    :value="item.code"
                                    :key="item.code"
                                />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="业务类型">
                            <el-select placeholder="业务类型" v-model="form.bizType">
                                <el-option label="请选择" value="" />
                                <el-option label="充值" value="1" />
                                <el-option label="退款" value="2" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row style="margin-top: 16px">
                    <el-col :span="8">
                        <el-form-item label="结算状态">
                            <el-select placeholder="结算状态" v-model="form.monthSettleStatus">
                                <el-option label="请选择" value="" />
                                <el-option label="已结算" value="1" />
                                <el-option label="待结算" value="0" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="会员">
                            <el-input placeholder="会员名称/手机号/会员卡号" v-model="form.search" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <ef-start-date v-model="form.startTime" />
                    </el-col>
                </el-row>
                <el-row style="margin-top: 16px">
                    <el-col :span="8">
                        <ef-end-date v-model="form.endTime" />
                    </el-col>
                </el-row>
            </el-form>
        </el-card>
        <el-card shadow="never" style="margin-top: 8px; text-align: left">
            <el-button
                type="primary"
                @click="handleQuery"
                size="small"
                v-if="hasPrivilege('menu.report.member.rechargeRefundSummary.open')"
                >查询
            </el-button>
            <el-button
                type="primary"
                @click="handleExport"
                size="small"
                v-if="hasPrivilege('menu.report.member.rechargeRefundSummary.export')"
                >导出
            </el-button>
            <span style="float: right">
                <el-button size="mini" @click="showCheckTableShowColumnDialog">表头</el-button>
                <el-button size="mini" @click="handlePrint">打印</el-button>
            </span>
        </el-card>
        <el-card shadow="never" style="margin-top: 8px">
            <el-table
                id="printMe"
                ref="table"
                border
                stripe
                style="width: 100%"
                :data="tableData"
                size="mini"
                :highlight-current-row="true"
                max-height="480"
            >
                <el-table-column label="全选" type="selection" width="60" align="center" fixed="left" />
                <el-table-column label="序号" width="60" type="index" fixed="left" />
                <el-table-column
                    label="机构组名称"
                    width="160"
                    prop="deptGroupName"
                    fixed="left"
                    v-if="showColumn('deptGroupName')"
                />
                <el-table-column
                    label="机构名称"
                    width="160"
                    prop="deptName"
                    fixed="left"
                    v-if="showColumn('deptName')"
                />
                <el-table-column
                    label="会员名称"
                    width="200"
                    prop="memberName"
                    fixed="left"
                    v-if="showColumn('memberName')"
                />
                <el-table-column label="业务类型" width="120" prop="bizType" key="bizType" v-if="showColumn('bizType')">
                    <template slot-scope="scope">
                        <span>{{ (scope.row.bizType || '') | bizTypeName }}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    label="结账状态"
                    width="120"
                    prop="settleStatus"
                    key="settleStatus"
                    v-if="showColumn('settleStatus')"
                >
                    <template slot-scope="scope">
                        <span v-if="scope.row.code">{{ scope.row.monthSettleStatus ? '已结算' : '待结算' }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="手机号" width="200" prop="mobile" v-if="showColumn('mobile')" />
                <el-table-column label="会员卡号" width="200" prop="cardNumber" v-if="showColumn('cardNumber')" />
                <el-table-column
                    label="充值笔数"
                    width="200"
                    prop="rechargeCounts"
                    align="right"
                    v-if="showColumn('rechargeCounts')"
                />
                <el-table-column
                    label="充值金额"
                    width="200"
                    prop="rechargeMoney"
                    key="rechargeMoney"
                    align="right"
                    v-if="showColumn('rechargeMoney')"
                >
                    <template slot-scope="scope">
                        <span>{{ scope.row.rechargeMoney | mFour }}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    label="退款笔数"
                    width="200"
                    prop="refundCounts"
                    align="right"
                    v-if="showColumn('refundCounts')"
                />
                <el-table-column
                    label="退款金额"
                    width="200"
                    prop="refundMoney"
                    key="refundMoney"
                    align="right"
                    v-if="showColumn('refundMoney')"
                >
                    <template slot-scope="scope">
                        <span>{{ scope.row.refundMoney | mFour }}</span>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination
                :page-sizes="page.pageSizes"
                :page-size="form.limit"
                :layout="page.PageStyle"
                :total="page.total"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                @prev-click="handlePrevClick"
                @next-click="handleNextClick"
            />
        </el-card>
        <check-table-show-column-dialog ref="checkTableShowColumnDialog" @checkTableShowColumn="setShowTableColumns" />
    </div>
</template>

<script>
import Util from '../../../../js/Util';
import UrlUtils from '../../../../js/UrlUtils';
import EfStartDate from '../../../components/EfStartDate';
import EfEndDate from '../../../components/EfEndDate';
import CheckTableShowColumn from 'components/mixins/CheckTableShowColumn';
import CheckTableShowColumnDialog from '../../../components/CheckTableShowColumnDialog';

export default {
    name: 'RechargeRefundSummary',
    components: { EfEndDate, EfStartDate, CheckTableShowColumnDialog },
    mixins: [CheckTableShowColumn],
    data() {
        return {
            emptyZero: true,
            form: {
                deptGroupCode: '',
                deptCode: '',
                bizType: '',
                monthSettleStatus: '',

                search: '',
                startTime: '',
                endTime: '',
                page: 1,
                limit: Util.Limit,
            },
            page: {
                total: 0,
                pageSizes: Util.PageSizes,
                PageStyle: Util.PageStyle,
            },
            loading: false,
            tableData: [],
            url: {
                queryDeptByGroup: '/system/dept/deptTree',
                page: '/report/memberRechargeRefundSummary',
            },
            meta: {
                deptGroups: [],
                types: [],
            },
        };
    },
    mounted() {
        const _this = this;
        UrlUtils.DeptGroup(_this, (rst) => {
            _this.meta.deptGroups = rst || [];
            this.handleQuery();
        });
    },
    methods: {
        handleQuery() {
            const _this = this;

            const _params = { params: _this.form };
            Util.queryTable(_this, _this.url.page, _params);
        },

        handleExport() {
            UrlUtils.Export(this, '会员充值退款汇总', '/report/memberRechargeRefundSummary/export', this.form);
        },

        handleSelectDeptGroup(groupCode) {
            const _this = this;
            _this.meta.types = [];
            _this.form.deptCode = '';
            UrlUtils.QueryRemote(this, this.url.queryDeptByGroup + '?deptGroupCode=' + groupCode, (rst) => {
                _this.meta.types = rst;
            });
        },
        handlePrint() {
            this.printHTML('printMe', this.$route.meta.title);
        },
    },
    filters: {
        bizTypeName(type) {
            return type == 1 ? '充值' : type == 2 ? '退款' : '';
        },
    },
};
</script>

<style scoped>
.RechargeRefundSummary .el-form-item {
    margin-bottom: 0;
}
</style>
